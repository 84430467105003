export const usePadBottom = size => {
  switch (size) {
    case 'small':
      return 'pb-12 md:pb-20';
    case 'medium':
      return 'pb-20 md:pb-32 lg:pb-40';
    case 'large':
      return 'pb-24 md:pb-48 lg:pb-56';
    default:
      return '';
  }
}
