import React from 'react'

export const Scroll = () => {
  return (
    <>
      <svg x="0px" y="0px" viewBox="0 0 100 125" className="fill-current w-12 h-12 mx-auto">
        <g>
          <path d="M50,19.4c-1.7,0-3,1.3-3,3v48.7L27.4,54.3c-1.3-1.1-3.2-0.9-4.2,0.3c-1.1,1.3-0.9,3.2,0.3,4.2l24.5,21   c0.6,0.5,1.3,0.7,2,0.7s1.4-0.2,2-0.7l24.5-21c1.3-1.1,1.4-3,0.3-4.2c-1.1-1.3-3-1.4-4.2-0.3L53,71.1V22.4   C53,20.7,51.7,19.4,50,19.4z" />
        </g>
      </svg>
    </>
  );
}
