import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Image from 'gatsby-plugin-sanity-image';

const aniVars = {
  hidden: {
    opacity: 0,
    scale: 0.97,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.3,
      duration:0.5,
    },
  },
};

export const ProjectGridItem = ({ image, index, caption, location, category, title }) => {

  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.1, triggerOnce: true });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      className={`col-span-1 flex flex-col lg:flex-row items-center gap-4 lg:gap-12 ${
        index % 2 === 0 ? '' : 'lg:flex-row-reverse'
      }`}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
      variants={aniVars}
    >
      <Image className="w-full lg:w-2/3" {...image} />
      <div className="w-full lg:w-1/3 font-display text-brand">
        <p className="lg:text-xl">
          {category}
          <span className="mx-2">-</span>
          {location}
        </p>
        <h4 className="text-2xl lg:text-4xl my-4">{title}</h4>
        <p className="prose xl:prose-lg font-body leading-normal text-gray">{caption}</p>
      </div>
    </motion.div>
  );
};
