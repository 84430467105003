import React from 'react';

export const Close = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <path
          d="M12.915 11.0139L21.6088 2.29216C22.1399 1.76103 22.1399 0.922399 21.6088 0.391268C21.0777 -0.139863 20.239 -0.139863 19.7079 0.391268L11.0141 9.08504L2.2924 0.391268C1.76127 -0.139863 0.922643 -0.139863 0.391512 0.391268C-0.139619 0.922399 -0.139619 1.76103 0.391512 2.29216L9.08529 10.9859L0.391512 19.7077C-0.139619 20.2388 -0.139619 21.0774 0.391512 21.6086C0.643101 21.8601 1.00651 21.9999 1.34196 21.9999C1.67741 21.9999 2.04081 21.8601 2.2924 21.6086L10.9862 12.9148L19.68 21.6086C19.9315 21.8601 20.2949 21.9999 20.6304 21.9999C20.9658 21.9999 21.3293 21.8601 21.5808 21.6086C22.112 21.0774 22.112 20.2388 21.5808 19.7077L12.915 11.0139Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
