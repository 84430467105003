import React from 'react';
import { motion } from 'framer-motion';
import { useLocation } from '@reach/router';
import { Formik, Form, Field, ErrorMessage } from 'formik';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export const CapitalForm = () => {
  const { pathname } = useLocation();

  const inputClasses =
    'appearance-none rounded-none bg-brand border-b w-full border-current py-3 lg:text-lg placeholder-white placeholder-opacity-30 lg:mb-6 2xl:mb-8';

  const labelClasses = 'tracking-wide font-headline mb-2';

  const errorClasses = 'tracking-wide text-sm my-2';

  return (
    <div>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          contactMethod: 'Email',
          email: '',
          phone: '',
          occupation: '',
          company: '',                    
        }}
        validate={(values) => {
          const errors = {};
          if (!values.firstName) {
            errors.firstName = 'First Name is Required';
          }
          if (!values.lastName) {
            errors.lastName = 'Last Name is Required';
          }
          if (values.contactMethod === 'Email') {
            if (!values.email) {
              errors.email = 'Email address is Required';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = 'Invalid email address';
            }
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          fetch(pathname || '/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
              'form-name': `contact-form`,
              ...values,
            }),
          })
            .then(() => {
              setSubmitting(false);
              setStatus('success');
            })
            .catch((error) => alert(error));
        }}
      >
        {({
          onChange,
          isSubmitting,
          status,
          values,
          /* and other goodies */
        }) => (
          <Form
            className="w-full relative"
            name="contact-form"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            subject="Website Enquiry"
          >
            {/* Hidden Fields for netlify */}
            <input type="hidden" name="form-name" value="contact-form" />
            <p hidden>
              <label>
                <input name="bot-field" onChange={onChange} />
              </label>
            </p>
            <>
              <div
                className={`font-body transition-all delay-300 duration-300 absolute top-2 left-0 right-0 z-10 ${
                  status === 'success'
                    ? 'opacity-100 pointer-events-all'
                    : 'opacity-0 pointer-events-none'
                }`}
              >
                <motion.h4
                  style={{color:'white'}}
                  className={`font-headline text-2xl`}
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                >
                  Thank you, we will be in touch.
                </motion.h4>
              </div>
              <div
                className={`grid gap-x-12 grid-cols-1 md:grid-cols-2 gap-y-6 font-body text-medium transition-all duration-300 ${
                  status === 'success' && 'opacity-0 pointer-events-none'
                }`}
              >
                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="firstName">
                    My first name
                  </label>
                  <Field
                    type="text"
                    name="firstName"
                    className={inputClasses}
                    placeholder="James"
                  />
                  <ErrorMessage name="firstName" className={errorClasses} component="div" />
                </div>
                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="lastName">
                    My last name
                  </label>
                  <Field type="text" name="lastName" className={inputClasses} placeholder="Smith" />
                  <ErrorMessage name="lastName" className={errorClasses} component="div" />
                </div>
                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="email">
                    My email address
                  </label>
                  <Field
                    type="email"
                    name="email"
                    className={inputClasses}
                    placeholder="james.smith@email.com"
                  />
                  <ErrorMessage name="email" className={errorClasses} component="div" />
                </div>
                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="phone">
                    My phone number
                  </label>
                  <Field
                    type="text"
                    name="phone"
                    className={inputClasses}
                    placeholder="+64 21 234 567"
                  />
                </div>
                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="occupation">
                    My occupation
                  </label>
                  <Field
                    type="text"
                    name="occupation"
                    className={inputClasses}
                    placeholder="Civil Engineer"
                  />
                </div>
                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="company">
                    My company
                  </label>
                  <Field
                    type="text"
                    name="company"
                    className={inputClasses}
                    placeholder="Company Name"
                  />
                </div>                                
                <div className="col-span-1 lg:col-span-2 flex justify-end mt-4">
                  <button className="underline font-headline text-lg" type="submit">
                    Submit Enquiry
                  </button>
                </div>
              </div>
            </>
          </Form>
        )}
      </Formik>
    </div>
  );
};
