export const usePadTop = size => {
  switch (size) {
    case 'small':
      return 'pt-12 md:pt-20';
    case 'medium':
      return 'pt-20 md:pt-32 lg:pt-40';
    case 'large':
      return 'pt-24 md:pt-48 lg:pt-56';
    default:
      return '';
  }
}
