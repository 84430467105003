import React, { createContext, useContext } from 'react';

// Hooks
import { useToggle } from '../hooks';

export const AppState = createContext({
  headerLight: false,
  navOpen: false,
});

export const AppWrapper = ({ children }) => {
  const [headerLight, toggleHeaderLight] = useToggle(false);      
  const [navOpen, setNavOpen, toggleNav] = useToggle(false);

  return (
    <AppState.Provider
      value={{
        navOpen,
        setNavOpen,
        toggleNav,
        headerLight,
        toggleHeaderLight,
      }}
    >
      {children}
    </AppState.Provider>
  );
};

export const useAppState = () => useContext(AppState);
